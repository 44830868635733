import React from "react";
import Helmet from "react-helmet";
import config from "../../data/SiteConfig";
import Layout from "../layout";
import AvatarLinks from "../components/Avatar/AvatarLinks";
import avatar_photo from "../../static/avatar.png";


/** @jsx jsx */
import { Styled, jsx } from "theme-ui";

class Index extends React.Component {
  render() {
    const { avatar } = config;
    return (
      <Layout>
        <Helmet title={config.siteTitle} />

        <div sx={{ display: 'grid', gridGap: 4, gridTemplateColumns: ['auto', '200px auto'], marginTop: `120px`, marginBottom: `30px`}}>
          <div sx={{ textAlign: `center`, float: `left`, marginTop: `28px` }}>
            <img src={avatar_photo} sx={{ width: 165, borderRadius: `5px` }} />
            <Styled.h1 sx={{ color: `text` }}>
              <Styled
                sx={{
                  fontWeight: `normal`,
                  fontSize: 32,
                  color: `text`,
                  textDecoration: `none`,
                }}
              >
                Amin Haeri
              </Styled>

              <Styled
                sx={{
                  fontWeight: `normal`,
                  fontSize: 18,
                  color: `text`,
                  textDecoration: `none`,
                  marginTop: `10px`
                }}
              >
                امین حائری
              </Styled>

              <Styled
                sx={{
                  fontWeight: `normal`,
                  fontSize: 18,
                  color: `primary`,
                  textDecoration: `none`,
                  marginTop: `20px`
                }}
              >
                ML/Data Scientist
              </Styled>

            </Styled.h1>

            <AvatarLinks />
          </div>

          <div sx={{ textAlign: `left`, float: `right` }}>
            <Styled.p>
              As a machine learning researcher/engineer, I have experience in the development and implementation of various machine learning applications across industrial and academic settings. With a good understanding of the latest advancements in the field and a passion for leveraging AI to solve real-world problems, I have been instrumental in driving innovation and delivering tangible results.
            </Styled.p>
              
            <Styled.p> 
              I am currently a data scientist at <a href = "https://www.td.com/ca/en/personal-banking" target = "_blank" sx={{ color: `primary`, textDecoration: `none`, ":hover": {bg: `muted`, borderRadius: `5px` }}}>TD</a>, developing machine learning models in finance. Before that, I did a postdoc with Dr. <a href = "https://pme.uchicago.edu/group/de-pablo-group" target = "_blank" sx={{ color: `primary`, textDecoration: `none`, ":hover": {bg: `muted`, borderRadius: `5px` }}}>Juan de Pablo</a> on machine learning simulations at <a href = "https://www.uchicago.edu" target = "_blank" sx={{ color: `primary`, textDecoration: `none`, ":hover": {bg: `muted`, borderRadius: `5px` }}}>The University of Chicago</a>, and worked as a software developer at <a href = "https://www.cm-labs.com" target = "_blank" sx={{ color: `primary`, textDecoration: `none`, ":hover": {bg: `muted`, borderRadius: `5px` }}}>CM Labs Simulations</a>.
            </Styled.p>

            <Styled.p>
              I received my Ph.D. in Computer Engineering under the supervision of Dr. <a href = "http://users.encs.concordia.ca/~kskoniec/" target = "_blank" sx={{ color: `primary`, textDecoration: `none`, ":hover": {bg: `muted`, borderRadius: `5px` }}}>Krzysztof Skonieczny</a> at <a href = "https://www.concordia.ca" target = "_blank" sx={{ color: `primary`, textDecoration: `none`, ":hover": {bg: `muted`, borderRadius: `5px` }}}>Concordia University</a> in 2021. I have researched both machine learning and physics simulations of robot-terrain interactions. My research was partly supported by <a href = "https://www.cm-labs.com" target = "_blank" sx={{ color: `primary`, textDecoration: `none`, ":hover": {bg: `muted`, borderRadius: `5px` }}}>CM Labs Simulations Inc.</a> where I worked at as a software engineer later on. Also, I received my B.Sc. and M.Sc. degrees with honor in Aerospace Engineering from <a href = "https://aut.ac.ir/en" target = "_blank" sx={{ color: `primary`, textDecoration: `none`, ":hover": {bg: `muted`, borderRadius: `5px` }}}>Amirkabir</a> and <a href = "http://www.en.sharif.edu/" target = "_blank" sx={{ color: `primary`, textDecoration: `none`, ":hover": {bg: `muted`, borderRadius: `5px` }}}>Sharif University</a> in 2015 and 2017.
            </Styled.p>

            {/* <Styled.p>
              My <strong>resume</strong> can be downloaded from <a href = "https://drive.google.com/uc?export=download&id=1Iw9f2iiypI0obJ7tPCWyiPkf0GrbDYTJ" sx={{ color: `primary`, textDecoration: `none`, ":hover": {bg: `muted`, borderRadius: `5px` }}}>here</a>.
            </Styled.p> */} 
            
            <Styled.p>
              {/* Email:&ensp; me <strong>at</strong> aminhaeri <strong>dot</strong> com */}
              {/* Email:&ensp; me@aminhaeri.com */}
              <a href = "https://drive.google.com/file/d/1D1j5llTBEvbqnbM4JJWxG55jmnhZxhfL/view?usp=sharing" sx={{ color: `primary`, textDecoration: `none`, ":hover": {bg: `muted`, borderRadius: `5px` }}}>Resume</a> | me <strong>at</strong> aminhaeri <strong>dot</strong> com
            </Styled.p>
            
          </div>
        </div>


        <div sx={{ display: 'grid', gridGap: 4, gridTemplateColumns: ['auto', 'auto auto auto'] }}>
          <div sx={{ textAlign: `left`, float: `left`}}>
            <Styled.h1>Education</Styled.h1>
            <Styled.li sx={{ textAlign: 'left', fontSize: 20, listStyleType: `square` }}>Postdoc in ML, U of Chicago, 2021-22</Styled.li>
            <Styled.li sx={{ textAlign: 'left', fontSize: 20, listStyleType: `square` }}>PhD in Comp, Concordia U, 2017-21</Styled.li>
            <Styled.li sx={{ textAlign: 'left', fontSize: 20, listStyleType: `square` }}>MSc in Aero, Sharif U, 2015-17</Styled.li>
            <Styled.li sx={{ textAlign: 'left', fontSize: 20, listStyleType: `square` }}>BSc in Aero, Amirkabir U, 2011-15</Styled.li>
          </div>

          <div sx={{ textAlign: `left`, float: `left`}}>
            <Styled.h1>Interests</Styled.h1>
            <Styled.li sx={{ textAlign: 'left', fontSize: 20, listStyleType: `square` }}>Machine Learning</Styled.li>
            <Styled.li sx={{ textAlign: 'left', fontSize: 20, listStyleType: `square` }}>Deep Generative Models</Styled.li>
            <Styled.li sx={{ textAlign: 'left', fontSize: 20, listStyleType: `square` }}>Computational Methods</Styled.li>
          </div>
          
          <div sx={{ textAlign: `left`, float: `left`}}>
              <Styled.h1>News</Styled.h1>

              <Styled.li sx={{ textAlign: 'left', fontSize: 20, listStyleType: `square` }}>
                <small><b>2024 May:</b></small> Presentation at <a href = "https://2024.ieee-icra.org/" target = "_blank" sx={{ color: `primary`, textDecoration: `none`, ":hover": {bg: `muted`, borderRadius: `5px` }}}>ICRA Conference</a>
              </Styled.li>

              <Styled.li sx={{ textAlign: 'left', fontSize: 20, listStyleType: `square` }}>
                <small><b>2021 April:</b></small> Presentation at <a href = "https://ascelibrary.org/doi/abs/10.1061/9780784483374.006" target = "_blank" sx={{ color: `primary`, textDecoration: `none`, ":hover": {bg: `muted`, borderRadius: `5px` }}}>ASCE Earth&Space</a>
              </Styled.li>

              <Styled.li sx={{ textAlign: 'left', fontSize: 20, listStyleType: `square` }}>
                <small><b>2021 March:</b></small> Best Paper Award in Software and Computing track at <a href = "https://aeroconf.org" target = "_blank" sx={{ color: `primary`, textDecoration: `none`, ":hover": {bg: `muted`, borderRadius: `5px` }}}>IEEE Aerospace</a>
              </Styled.li>

              {/* <Styled.li sx={{ textAlign: 'left', fontSize: 20, listStyleType: `square` }}>
                <small><b>2020 Oct:</b></small> Presentation at <a href = "https://www.iaarc.org/publications/2020_proceedings_of_the_37th_isarc/efficient_numerical_methods_for_accurate_modeling_of_soil_cutting_operations.html" target = "_blank" sx={{ color: `primary`, textDecoration: `none`, ":hover": {bg: `muted`, borderRadius: `5px` }}}>ISARC Conference</a>
              </Styled.li> */}
          </div>

        </div>

      </Layout>
    );
  }
}

export default Index;
